
@mixin color-base($interactive:false) {
  background-color: var(--surface);
  color: var(--on-surface);

  @if $interactive {
    &:hover{
      background-color: var(--surface-hover);
    }

    &:active,
    &--selected{
      background-color: var(--surface-selected);
      color: var(--on-surface-selected);
    }
  }
}

@mixin color-primary($interactive:false) {
  background-color: var(--primary-surface);
  color: var(--on-primary);

  @if $interactive {
    &:hover{
      background-color: var(--primary-hover);
    }

    &:active,
    &--selected{
      background-color: var(--primary-selected);
    }
  }
}

@mixin color-secondary($interactive:false) {
  background-color: var(--secondary-surface);
  color: var(--on-secondary);

  @if $interactive {
    &:hover{
      background-color: var(--secondary-hover);
    }

    &:active,
    &--selected{
      background-color: var(--secondary-selected);
    }
  }
}

@mixin color-success($interactive:false) {
  background-color: var(--success-surface);
  color: var(--on-success);

  @if $interactive {
    &:hover{
      background-color: var(--success-secondary);
    }

    &:active,
    &--selected{
      background-color: var(--success-indicator);
    }
  }
}

@mixin color-warning($interactive:false) {
  background-color: var(--warning-surface);
  color: var(--on-warning);

  @if $interactive {
    &:hover{
      background-color: var(--warning-secondary);
    }

    &:active,
    &--selected{
      background-color: var(--warning-indicator);
    }
  }
}

@mixin color-error($interactive:false) {
  background-color: var(--error-surface);
  color: var(--on-error);

  @if $interactive {
    &:hover{
      background-color: var(--error-secondary);
    }

    &:active,
    &--selected{
      background-color: var(--error-indicator);
    }
  }
}

@mixin color-disabled() {
  &,
  &:hover
  &:active,
  &--selected{
    background-color: var(--disabled);
    color: var(--primary-selected-text);
  }
}

@mixin color-separator() {
  background-color: var(--separator);
  color: var(--separator); // hidden ¯\_(ツ)_/¯
}

@mixin color-snackbar() {
    background-color: var(--fixed-snackbar);
    color: var(--primary-selected-text);
}