@use "sass:map";
@use "sass:list";
@use "sass:color";
@forward "./colors";

$palettes: (
  legacy: ( // old color schemne : Elevation, glassy etc will change so I just reused old colors
    pure-white: #FFF,
    light-gray: #dcddde,
    mid-gray: #5D5F62,
    gray: #A9A9A9,
    dark-gray: #1c1c1c,
    pure-black: #000,
    evident-blue: #005CB9,
    light-evident-blue: #B3D5F7,
    mid-evident-blue: #93c6f9,
    dark-evident-blue: #00417d,
    primary: #005CB9,
    primary-selected: #0C2340,
    evident-extra-transparent: #0000001a,
    evident-light-transparent: #00000026,
    evident-mid-transparent: #00000080
  ),
  evident: ( // same for both themes
    black: #000,
    light-gray: #DCDDDE,
    white: #FFF,
    blue: #005CB9,
    navy-blue: #0C2340,
    teal-blue: #004F71,
    light-blue: #56B7E6,
    orange: #FF6900,
    yellow: #FFC72C,
  ),
  light: (
    statics:  (#FFF, #F7F9F9, rgba(#18191b, 0.1), #18191B),
    neutrals: (#F0F2F4, #E8EAEE, #D5D9E0, #C2C7D1, #AFB5C2, #9BA2B2, #858EA1, #6F798E, #5C6475, #464B57, #393E47),
    blues:    (#DAECFF, #BADCFF, #98CBFF, #75B9FF, #4DA5FF, #1D8DFF, #0074EA, #005CB9, #004890, #003B77),
    success:   (#DCEFDC, #BFE1BF, #A1D3A1, #81C481, #5EB45E, #35A135, #058B05, #007200, #155517, #1C461F),
    warnings:  (#FFE6AB, #FFD37C, #FFC061, #FFA342, #FF8624, #F67013, #E55E00, #C24000, #942A00, #661B00),
    errors:     (#FDE5E0, #FBCEC5, #F9B6AE, #F79C95, #F57E78, #F25854, #ED1110, #BF0000, #970000, #7E0000),
  ),
  dark: (
    statics:  (#191B1D, #1E2024, rgba(#fbfbfc, 0.1), #FBFBFC),
    neutrals: (#23252B, #292C33, #3C404B, #4C515F, #5A6172, #6A7387, #858EA1, #8F97A8, #A2A9B8, #B6BCC8, #CCD0D9),
    blues:    (#1F2D3B, #243950, #284563, #2B527A, #2D5F95, #2D72B3, #3486DB, #5CA5EF, #8DC2F9, #B1D5FB),
    success:   (#1F3A22, #1A4A1D, #125E13, #0A720B, #018601, #219821, #49AB49, #6BBB6B, #8ECB8E, #B0DBB0),
    warnings:  (#712003, #882C06, #A23907, #BB4606, #D85504, #EE6807, #F18B2D, #F3A84E, #F7C373, #FDDD9C),
    errors:     (#641010, #811414, #A01919, #BE1E1E, #E02323, #F1433B, #F76B59, #FB8971, #FCA694, #FDC2B6),
  ),
);

// Theme config
// !!! temporary mix legacy scheme because UI will change for those...
$themes: (
  light: (
    fixed-snackbar: (#1F2D3B),
    fixed-pure-white: (#FFF),
    elevations: (
      xs: rgba(map.get($palettes, legacy, mid-gray), .25),
      md: (rgba(map.get($palettes, legacy, pure-black), .10), rgba(map.get($palettes, legacy, pure-black), .15)),
      lg: (rgba(map.get($palettes, legacy, pure-black), .15), rgba(map.get($palettes, legacy, pure-black), .30)),
    ),
    shadows: (
      nav: rgba(map.get($palettes, legacy, mid-gray), .25),
      menu: rgba(map.get($palettes, legacy, mid-gray), .25),
      tooltip: rgba(map.get($palettes, legacy, mid-gray), .25),
      modal: rgba(map.get($palettes, legacy, mid-gray), .25),
      hover-selected: (map.get($palettes, legacy, evident-extra-transparent), map.get($palettes, legacy, evident-light-transparent)),
    ),
    glassy: (
      menu: rgba(map.get($palettes, legacy, pure-white), .9),
      nav: rgba(map.get($palettes, legacy, pure-white), .55),
      primary-double-menu: rgba(map.get($palettes, legacy, pure-white), .45),
      secondary-double-menu: rgba(map.get($palettes, legacy, pure-white), .65),
    ),
    backdrop: rgba(map.get($palettes, legacy, gray), .50),
    gradient: (
      main: (#005CB9, #00417D),
      cta: (map.get($palettes, legacy, evident-blue), map.get($palettes, legacy, dark-evident-blue)),
      stroke: (rgba(map.get($palettes, legacy, light-gray), .75), rgba(map.get($palettes, legacy, pure-white), .75)),
    ),
  ),
  dark: (
    fixed-snackbar: (#1F2D3B),
    fixed-pure-white: (#FFF),
    elevations: (
      xs: rgba(map.get($palettes, legacy, pure-black), .25),
      md: (rgba(map.get($palettes, legacy, pure-black), .15), rgba(map.get($palettes, legacy, pure-black), .30)),
      lg: (rgba(map.get($palettes, legacy, pure-black), .15), rgba(map.get($palettes, legacy, pure-black), .50)),
    ),
    shadows: (
      nav: rgba(map.get($palettes, legacy, pure-black), .25),// $nav-box-shadow
      menu: rgba(map.get($palettes, legacy, pure-black), .25),// $menu-box-shadow
      tooltip: rgba(map.get($palettes, legacy, pure-black), .25), // $tooltip-box-shadow
      modal: rgba(map.get($palettes, legacy, pure-black), .25), // $modal-box-shadow
      hover-selected: (map.get($palettes, legacy, evident-light-transparent), map.get($palettes, legacy, evident-mid-transparent)),
    ),
    glassy: (
      menu: rgba(map.get($palettes, legacy, dark-gray), .9), // $menu-glassy-background
      nav: rgba(map.get($palettes, legacy, dark-gray), .55), // $nav-glassy-background
      primary-double-menu: rgba(map.get($palettes, legacy, dark-gray), .45), // $primary-double-menu-glassy-background
      secondary-double-menu: rgba(map.get($palettes, legacy, dark-gray), .65), // $secondary-double-menu-glassy-background
    ),
    backdrop: rgba(map.get($palettes, legacy, gray), .50), // $modal-backdrop
    gradient: (
      main: (#77B7F8, #2C89E8),
      cta: (map.get($palettes, legacy, light-evident-blue), map.get($palettes, legacy, mid-evident-blue)), // $call-to-action-background
      stroke: (rgba(map.get($palettes, legacy, dark-gray), .75), rgba(map.get($palettes, legacy, dark-gray), .75)), // $stroke-linear-gradient
    ),
  )
);



/** Nico : I REALLY prefer this nomenclature
  - but would need to remap older names (get-theme-colors-remap-old)
*/
@mixin get-theme-colors($theme) {
  // evident colors (same both themes)
  $evident: map.get($palettes, evident);

  --black: #{map.get($evident, errors)};
  --light-gray: #{map.get($evident, light-gray)};
  --white: #{map.get($evident, white)};
  --blue: #{map.get($evident, blue)};
  --navy: #{map.get($evident, navy-blue)};
  --teal: #{map.get($evident, teal-blue)};
  --blue-light: #{map.get($evident, light-blue)};
  --orange: #{map.get($evident, orange)};
  --yellow: #{map.get($evident, yellow)};

  // retrieve theme palettes entries
  $current-palette: map.get($palettes, $theme);
  $statics: map.get($current-palette, statics);
  $neutrals: map.get($current-palette, neutrals);
  $blues: map.get($current-palette, blues);
  $success: map.get($current-palette, success);
  $warnings: map.get($current-palette, warnings);
  $errors: map.get($current-palette, errors);

  // provide all shades : var(--blues-6); var(--warnings-2) ...
  @each $name, $colors in $current-palette {
    @each $color in $colors {
      $i: list.index($colors, $color);

      --#{$name}-#{$i}: #{$color};
    }
  }

  // retrieve theme entries
  $current-theme: map.get($themes, $theme);
  $fixed-snackbar: map.get($current-theme, fixed-snackbar);
  $fixed-pure-white: map.get($current-theme, fixed-pure-white);
  $elevations: map.get($current-theme, elevations);
  $shadows: map.get($current-theme, shadows);
  $glassy: map.get($current-theme, glassy);
  $backdrop: map.get($current-theme, backdrop);
  $gradient: map.get($current-theme, gradient);
  $primary-accent: list.nth($blues, 8);
  $primary-selected: list.nth($blues, 9);
  $secondary-accent: list.nth($blues, 1);
  $secondary-selected: list.nth($blues, 2);
  $xs: map.get($elevations, xs);
  $md: map.get($elevations, md);
  $lg: map.get($elevations, lg);
  $hover-selected: map.get($shadows, "hover-selected");
  $main: map.get($gradient, "main");
  $cta: map.get($gradient, "cta");
  $stroke: map.get($gradient, "stroke");

  // base
  --surface: #{list.nth($statics, 1)};
  --surface-hover: #{list.nth($neutrals, 1)};
  --surface-selected: #{list.nth($blues, 1)};
  --on-surface: #{list.nth($statics, 4)};
  --on-surface-secondary: #{list.nth($neutrals, 9)};
  --on-surface-selected: #{list.nth($blues, 8)};
  // accent - primary
  --primary-surface: #{$primary-accent};
  --primary-indicator: #{list.nth($blues, 7)};
  --primary-selected: #{$primary-selected};
  --primary-hover: #{color.mix($primary-selected, $primary-accent, 50%)};
  --on-primary: #{list.nth($statics, 1)};
  // accent - secondary
  --secondary-surface: #{$secondary-accent};
  --secondary-selected: #{$secondary-selected};
  --secondary-hover: #{color.mix($secondary-selected, $secondary-accent, 50%)};
  --on-secondary: #{list.nth($blues, 7)};
  // status errors
  --error-surface: #{list.nth($errors, 1)};
  --error-indicator: #{list.nth($errors, 7)};
  --error-primary: #{list.nth($errors, 8)};
  --error-secondary: #{list.nth($errors, 9)};
  --error-hover: #{color.mix(list.nth($errors, 8), list.nth($errors, 9), 50%)};
  --on-error: var(--error-primary);
  // status warnings
  --warning-surface: #{list.nth($warnings, 1)};
  --warning-indicator: #{list.nth($warnings, 7)};
  --warning-primary: #{list.nth($warnings, 8)};
  --warning-secondary: #{list.nth($warnings, 9)};
  --on-warning: var(--warning-primary);
  // status success
  --success-surface: #{list.nth($success, 1)};
  --success-indicator: #{list.nth($success, 7)};
  --success-primary: #{list.nth($success, 8)};
  --success-secondary: #{list.nth($success, 9)};
  --on-success: var(--success-primary);
  // others specifics
  --separator: #{list.nth($statics, 3)};
  --disabled: #{list.nth($neutrals, 5)};
  --fixed-snackbar: #{$fixed-snackbar};
  --fixed-pure-white: #{$fixed-pure-white};
  // extra : not defined in design system but used in ui
  --badge-border: #{list.nth($neutrals, 4)}; // used in badge component
  --surface-alt: #{list.nth($neutrals, 1)}; // used in admin badge component (alternative version)
  --surface-alt-hover: #{list.nth($neutrals, 2)};
  --general-background: linear-gradient(180deg, #{list.nth($statics, 2)} 0%, #{list.nth($statics, 1)} 100%);

  // !!! below is legacy - will change later

  // Elevations
  --elevation-xs: #{0 0 32px 0 $xs};
  --elevation-md: #{0 4px 8px 0 list.nth($md, 1), 0 1px 4px 0 list.nth($md, 2)};
  --elevation-lg: #{0 4px 8px 0 list.nth($lg, 1), 0 1px 4px 0 list.nth($lg, 2)};
  // Shadows
  --nav-box-shadow: #{0 0 32px 0 map.get($shadows, "nav")};
  --menu-box-shadow: #{0 0 32px 6px map.get($shadows, "menu")};
  --tooltip-box-shadow: #{0 0 8px 0 map.get($shadows, "tooltip")};
  --modal-box-shadow: #{0 0 16px 0 map.get($shadows, "modal")};
  --hover-selected-box-shadow: #{0 4px 8px 0 list.nth($hover-selected, 1), 0 1px 4px 0 list.nth($hover-selected, 2)};
  // Glassy
  --menu-glassy-background: #{map.get($glassy, "menu")};
  --nav-glassy-background: #{map.get($glassy, "nav")};
  --primary-double-menu-glassy-background: #{map.get($glassy, "primary-double-menu")};
  --secondary-double-menu-glassy-background: #{map.get($glassy, "secondary-double-menu")};
  // Backdrops
  --modal-backdrop: #{$backdrop};
  // Gradients
  --gradient: #{linear-gradient(90deg, list.nth($main, 1) 0%, list.nth($main, 2) 100%)};
  --call-to-action-background: #{linear-gradient(90deg, list.nth($cta, 1) 20%, list.nth($cta, 2) 90%)};
  --stroke-linear-gradient: #{linear-gradient(45deg, list.nth($stroke, 1), list.nth($stroke, 2))};
  --panel-backdrop-gradient: #{linear-gradient(90deg, transparent, list.nth($neutrals, 1) 100%)};
}
