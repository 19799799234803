// stylelint-disable
@use "sass:map";
@use './variables' as *;

/* Breakpoint mixin -- mobile first (min-width) */
@mixin breakpoint($bp, $rule: min-width) {
  @media all and (#{$rule}: map.get($breakpoints, $bp)) {
    @content;
  }
}

/* Unstyled default list */
@mixin unlist() {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

@mixin text-ellipsis() {
  overflow: hidden;
  width: 100%;
  min-width: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin sass-to-css($module) {
  @each $name, $value in $module {
    @if type-of($value) == "map" {
      --#{$name}: #{map.get($value, "hex")};
      --#{$name}-h: #{map.get($value, "h")};
      --#{$name}-s: #{map.get($value, "s")};
      --#{$name}-l: #{map.get($value, "l")};

      @if map.get($value, "color") {
        --#{$name}-color: #{map.get($value, "color")};
      }
    } @else {
      --#{$name}: #{$value};
    }
  }
}

@mixin elevation($zValue) {
  position: relative;
  z-index: map.get($elevations, $zValue);
}

@mixin number-input-in-text-style() {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin: 0;
    appearance: none;
  }

  /* Firefox */
  input[type="number"] {
    display: inline;
    appearance: textfield;
  }
}
