html,
body {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;

  * {
    box-sizing: border-box;
  }
}

button {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.app .app__platform {
  .apps-menu__item {
    &[data-name="admin"]{
      display: none;
    }
  }
}