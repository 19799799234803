@use "sass:map";
@use "@evc/mixins/typography" as *;
@use "@evc/mixins/menus" as *;
@use "@evc/mixins/animations" as *;
@use "@evc/core/index" as *;

.evc-app-title {
  position: sticky;
  z-index: map.get($elevations, page-title);
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $ratio-xl $ratio-xxl;
  border-bottom: 1px solid var(--separator);
  background-color: var(--on-primary);
  border-top-left-radius: $ratio-xl;
  border-top-right-radius: $ratio-xl;
}

.evc-page-title {
  @include heading1;
}

.evc-page-container {
  display: flex;
  flex-direction: column;
  padding: $page-padding-xxl $ratio-xxl;
}

.evc-page-infos {
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap : $page-padding-xxl;
}

.evc-content-title {
  @include title;
}

.evc-page-small-space {
  flex: 0 0 20%;
}

.evc-page-mid-space {
  width: 50%;
}

.evc-page-main-elem {
  flex: 1 1 auto;
}

.evc-page-divider {
  @include divider;

  width: 100%;
  margin: $page-padding-xxl 0;
}

.evc-rp-top-infos{
  display: flex;
  overflow: hidden;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: $default-gap;
}

.evc-rp-card{
  display: flex;
  overflow: hidden;
  flex: 1 1 100%;
}

.evc-rp-quick-actions{
  display: flex;
  flex: 0 0 auto;
  gap: calc(2 * $default-gap);
}