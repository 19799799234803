@use "@evc/core/index" as *;

@mixin heading1() {
  & {
    font-family: $primary-font;
    font-size: to-rem(32px);
    font-style: normal;
    font-weight: $bold;
    line-height: 43.58 / 32;
  }
}

@mixin app-name() {
  & {
    font-family: $primary-font;
    font-size: to-rem(24px);
    font-style: normal;
    font-weight: $bold;
    line-height: 32.69 / 24;
  }
}

@mixin big-title() {
  & {
    font-family: $primary-font;
    font-size: to-rem(24px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 32.69 / 24;
  }
}

@mixin big-description() {
  & {
    font-family: $primary-font;
    font-size: to-rem(24px);
    font-style: normal;
    font-weight: $regular;
    line-height: 43.58 / 24;
  }
}

@mixin big-badge-text() {
  & {
    font-family: $primary-font;
    font-size: to-rem(32px);
    font-style: normal;
    font-weight: $regular;
    line-height: 32.69 / 32;
  }
}

@mixin tabs() {
  & {
    font-family: $primary-font;
    font-size: to-rem(16px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 21.79 / 16;
  }
}

@mixin medium-description() {
  & {
    font-family: $primary-font;
    font-size: to-rem(16px);
    font-style: normal;
    font-weight: $regular;
    line-height: 21.79 / 16;
  }
}

@mixin medium-secondary-description() {
  @include medium-description;

  & {
    font-style: italic;
  }
}

@mixin title() {
  & {
    font-family: $primary-font;
    font-size: to-rem(18px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 24.52 / 18;
  }
}

@mixin small-title() {
  & {
    font-family: $primary-font;
    font-size: to-rem(14px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 19.07 / 14;
  }
}

@mixin description() {
  & {
    font-family: $primary-font;
    font-size: to-rem(14px);
    font-style: normal;
    font-weight: $regular;
    line-height: 19.07 / 14;
  }
}

@mixin secondary-description() {
  @include description;

  & {
    font-style: italic;
  }
}

@mixin tiny-title() {
  & {
    font-family: $primary-font;
    font-size: to-rem(12px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 16.34 / 12;
  }
}

@mixin small-description() {
  & {
    font-family: $primary-font;
    font-size: to-rem(12px);
    font-style: normal;
    font-weight: $regular;
    line-height: 16.34 / 12;
  }
}

@mixin small-secondary-description() {
  @include small-description;

  & {
    font-style: italic;
  }
}

@mixin status() {
  @include tiny-title;
}

@mixin chip-description() {
  & {
    font-family: $primary-font;
    font-size: to-rem(10px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 13.62 / 10;
  }
}

@mixin tiny-description() {
  & {
    font-family: $primary-font;
    font-size: to-rem(10px);
    font-style: normal;
    font-weight: $regular;
    line-height: 13.62 / 10;
  }
}

@mixin tiny-secondary-description() {
  @include tiny-description;

  & {
    font-style: italic;
  }
}

@mixin regular-cta-button() {
  & {
    font-family: $primary-font;
    font-size: to-rem(16px);
    font-style: normal;
    font-weight: $bold;
    line-height: 24 / 16;
  }
}

@mixin regular-button() {
  & {
    font-family: $primary-font;
    font-size: to-rem(16px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 20 / 16;
  }
}

@mixin small-cta-button() {
  & {
    font-family: $primary-font;
    font-size: to-rem(14px);
    font-style: normal;
    font-weight: $bold;
    line-height: 24 / 14;
  }
}

@mixin small-button() {
  & {
    font-family: $primary-font;
    font-size: to-rem(14px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 20 / 14;
  }
}

@mixin tiny-cta-button() {
  & {
    font-family: $primary-font;
    font-size: to-rem(12px);
    font-style: normal;
    font-weight: $bold;
    line-height: 24 / 12;
  }
}

@mixin tiny-button() {
  & {
    font-family: $primary-font;
    font-size: to-rem(12px);
    font-style: normal;
    font-weight: $semi-bold;
    line-height: 20 / 12;
  }
}