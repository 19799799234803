@use "sass:color";
@use 'sass:math';

$font-root: 14px;

/* Calculate PX into REM with default em ratio */
@function rem($pixels) {
  @return to-rem($pixels, 16px);
}

/* Calculate PX into REM with font-root ratio */
@function to-rem($pixels, $context: $font-root) {
  @if math.is-unitless($pixels) {
    $pixels: $pixels * 1px;
  }

  @return calc($pixels / $context * 1rem);
}

@function css-darken($color, $amount) {
  @return hsl(var(--#{$color}-h), var(--#{$color}-s), calc(#{var(--#{$color}-l)} - #{$amount}));
}

@function css-lighten($color, $amount) {
  @return hsl(var(--#{$color}-h), var(--#{$color}-s), calc(#{var(--#{$color}-l)} + #{$amount}));
}
