@use "sass:map";
@use "@evc/core/variables" as *;

@mixin menu-glassy() {
  position: fixed;
  z-index: map.get($elevations, menu);
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  border-radius: $radius-xs;
  backdrop-filter: $blur-md;
  background: var(--menu-glassy-background);
  box-shadow: var(--menu-box-shadow);
}

@mixin menu-padding() {
  padding-top: $ratio-xxs;
  padding-bottom: $ratio-xxs;
}

@mixin divider() {
  width: calc(100% - 2 * $gap-sm);
  border-top: 1px solid var(--separator);
  margin: $size-xs $gap-sm;
}

@mixin divider-full() {
  border-top: 1px solid var(--separator);
  margin-top: $size-xs;
  margin-bottom: $size-xs;
}

@mixin badge-size($size) {
  display: flex;
  width: $size;
  height: $size;
  align-items: center;
  justify-content: center;
  border-radius: $size-sm;
}

@mixin evident-scrollbar($scroll-direction, $size: $size-xs) {
  @supports not selector(::-webkit-scrollbar) {
    scrollbar-width: thin;
  }

  & {
    @if $scroll-direction == "auto" {
      overflow: auto;
    } @else if $scroll-direction == "horizontal" {
      overflow: hidden;
      overflow-x: auto;
    } @else {
      overflow: hidden;
      overflow-y: auto;
    }
  }

  &::-webkit-scrollbar {
    @if $scroll-direction == "auto" {
      width: $size;
      height: $size;
    } @else if $scroll-direction == "horizontal" {
      height: $size;
    } @else {
      width: $size;
    }

    border-radius: $round-radius;
    margin-left: 2px;
    background-color: var(--surface-hover);

  }

  &::-webkit-scrollbar-thumb {
    border-radius: $round-radius;
    margin-left: -2px;
    background: var(--secondary-selected);

  }

  &::-webkit-scrollbar-thumb:hover {
    background: var(--secondary-selected);
  }

  &::-webkit-scrollbar-thumb:active {
    background: var(--primary-surface);
  }
}

@mixin page-default-layout {
  width: 100%;
  padding: $page-padding-xxl $ratio-xxl $page-padding-xxl $ratio-md;
}

@mixin default-modal-actions-btn {
  display: flex;
  justify-content: flex-end;
  gap: $size-md;

  &--cancel {
    color: var(--on-surface-secondary)
  }
}
