@use "sass:meta";
@use "sass:map";
@use "sass:list";
@use "@evc/core/index" as *;
@use "@evc/theme/index" as *;

/* FONTS */
@import "https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wdth,wght@0,62.5..100,100..900;1,62.5..100,100..900&display=swap";

:root {
  font-size: $font-root;
}

.auto-theme {
  @include get-theme-colors(light);

  @media (prefers-color-scheme: dark) {
    @include get-theme-colors(dark);
  }
}

.dark-theme {
  @include get-theme-colors(dark);
}

.light-theme {
  @include get-theme-colors(light);
}

html{
  @include color-base;
}

input, textarea, select, button{
  color: var(--on-surface);
}