@use "../core/variables" as *;

@mixin gradient-on-hover-animation($primary-background, $secondary-background) {
  position: relative;

  &:hover {
    background: $secondary-background;
  }

  &:active {
    background: $primary-background;
  }

  &::before {
    --gradient-core-size: 0; // This var change depending on the button size

    position: absolute;
    z-index: -1; // To be sure that the gradient is behind the button text
    top: var(--y); // x-position of the cursor
    left: var(--x); // y-position of the cursor
    width: var(--gradient-core-size);
    height: var(--gradient-core-size);
    background: radial-gradient(circle closest-side, $primary-background 20%, transparent);
    content: "";
    transform: translate(-50%, -50%);
    transition:
      width 200ms ease,
      height 200ms ease;
  }

  &:active::before {
    background: radial-gradient(circle closest-side, $secondary-background 20%, transparent);
  }
}

@mixin fade-in-animation($fade-in-time: 300ms) {
  opacity: 0;
  transition: opacity $fade-in-time ease;

  &.show {
    opacity: 1;
  }
}

@keyframes spin-animation {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}